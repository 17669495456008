<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-dialog
      v-model="carga"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-h4 font-weight-light white--text">
          CARGANDO DATA...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <div>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-dialog
          v-model="dialogDelitos"
          width="500"
        >
          <v-card>
            <v-card-title class="text-h3 grey lighten-2">
              Información del juzgado
            </v-card-title>

            <v-card-text>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="nombreDelito"
                  label="Nombre del delito"
                  prepend-icon="mdi-account"
                  :rules="regla"
                />
              </v-col>
              <v-col
                v-if="actualizar"
                cols="12"
                md="8"
              >
                <v-autocomplete
                  v-model="estadoSeleccionado"
                  :items="itemsEstado"
                  hide-no-data
                  hide-selected
                  item-text="nombre"
                  item-value="id"
                  label="Estado del delito"
                  placeholder="Escriba o seleccione el estado del delito"
                  prepend-icon="mdi-database-search"
                  :rules="regla"
                />
              </v-col>
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-btn
                color="primary"
                text
                @click="cerrarDialog()"
              >
                Cerrar
              </v-btn>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="registrarDelito()"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </div>
    <v-row v-if="!carga">
      <v-col
        cols="12"
        lg="12"
      >
        <base-material-card
          icon="mdi-scale-balance"
          color="primary"
          title="Administración de juzgados"
        >
          <v-card-title>
            <v-btn
              class="ma-5 md-round"
              rounded
              medium
              color="green"
              @click="dialogDelitos = true"
            >
              <v-icon>
                mdi-plus-box-outline
              </v-icon>
            </v-btn>
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              class="ml-auto"
              label="Buscar..."
              hide-details
              single-line
              style="max-width: 250px;"
            />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            dense
            class="elevation-1"
            fixed-header
            :loading="carga"
            loading-text="Cargando, por favor espere"
            :footer-props="{
              'items-per-page-options': [25, 50, -1],
              'items-per-page-text': 'Mostrando:',
              'items-per-page-all-text': 'Todos'
            }"
            no-data-text="No hay datos registrados"
            no-results-text="No se encontraron resultados"
          >
            <template v-slot:[`item.estado`]="{ item }">
              <v-icon
                v-if="item.estado === '1'"
                color="green"
              >
                mdi-check-decagram
              </v-icon>
              <v-icon
                v-else
                color="red"
              >
                mdi-close-circle
              </v-icon>
              {{ item.estado === '1' ? 'ACTIVO' : 'DESACTIVADO' }}
            </template>
            <template v-slot:[`item.editar`]="{ item }">
              <v-btn
                color="green"
                fab
                x-small
                dark
                @click="editarDelito(item)"
              >
                <v-icon>mdi-pencil-box-multiple</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'ReporteDelitos',
    data () {
      return {
        headers: [
          {
            text: 'Editar',
            value: 'editar',
          },
          {
            text: 'ID juzgado',
            value: 'id',
          },
          {
            text: 'Nombre',
            value: 'nombre',
          },
          {
            text: 'Es sala',
            value: 'es_sala',
          },
          {
            text: 'Juez',
            value: 'juez',
          },
          {
            text: 'Estado',
            value: 'estado',
          },
        ],
        itemsEstado: [{
                        id: '1',
                        nombre: 'ACTIVO',
                      },
                      {
                        id: '99',
                        nombre: 'DESACTIVADO',
                      }],
        items: [],
        carga: true,
        dialogDelitos: false,
        search: '',
        actualizar: false,
        nombreDelito: '',
        estadoSeleccionado: '',
        idSeleccionado: '',
        urlUsuario: '',
        regla: [(v) => !!v || 'Campo requerido'],
      }
    },
    created () {
      if (!this.dataUsuario || parseInt(this.dataUsuario.es_admin) !== 1) {
        localStorage.removeItem('usuarioDataCSJLA')
        this.$router.push('/')
      }
      this.urlUsuario = `${window.__env.dataCfg.urlApiData}usuario/${localStorage.getItem('usuarioDataCSJLA')}/`
      this.$store.commit('SET_VALOR', false)
      window.scrollTo(0, 0)
    },
    methods: {
      leerDelitos () {
        axios
          .get(`${window.__env.dataCfg.urlApiData}tipos/`)
          .then((response) => {
            this.items = response.data.array
            this.carga = false
            this.cargaPartes = false
          })
          .catch((e) => {
                  console.log(e) // eslint-disable-line
            alert('NO SE PUEDE CONECTAR AL SERVICIO WEB')
            this.carga = false
          })
      },
      editarDelito (item) {
        this.actualizar = true
        this.dialogDelitos = true
        this.nombreDelito = item.nombre
        this.estadoSeleccionado = item.estado
        this.idSeleccionado = item.id
      },
      cerrarDialog () {
        this.actualizar = false
        this.dialogDelitos = false
        this.$refs.form.reset()
      },
      registrarDelito () {
        if (this.actualizar === true) {
          if (this.$refs.form.validate()) {
            this.loadingText = 'REGISTRANDO DELITO...'
            this.carga = true
            const data = {
              nombre: this.nombreDelito,
              estado: this.estadoSeleccionado,
              id: this.idSeleccionado,
            }
            axios
              .put(`${window.__env.dataCfg.urlApiData}tipo/`, data)
              .then((r) => {
                if (r.data.resp === true) {
                  alert('El delito se actualizó correctamente.')
                  this.carga = false
                  this.cerrarDialog()
                  this.leerDelitos()
                } else {
                  alert('No se pudo actualizar el delito.')
                  this.carga = false
                }
              })
              .catch((err) => {
                alert('Servicio no disponible' + err)
                this.carga = false
              })
          }
        } else {
          if (this.$refs.form.validate()) {
            this.loadingText = 'REGISTRANDO DELITO...'
            this.carga = true
            const data = {
              nombre: this.nombreDelito,
            }
            axios
              .post(`${window.__env.dataCfg.urlApiData}tipo/`, data)
              .then((r) => {
                if (r.data.resp === true) {
                  alert('El delito se registró correctamente.')
                  this.carga = false
                  this.cerrarDialog()
                  this.leerDelitos()
                } else {
                  alert('No se pudo registrar el delito.')
                  this.carga = false
                }
              })
              .catch((err) => {
                alert('Servicio no disponible' + err)
                this.carga = false
              })
          }
        }
      },
      validarEstado (r) {
        if (r.data.resp === true) {
          if (parseInt(r.data.user.estado) === 1) {
            localStorage.setItem('usuarioDataCSJLA', r.data.user.id)
            localStorage.setItem('rol', parseInt(r.data.user.rol))
            this.carga = false
          } else {
            alert('Usuario desactivado')
            this.$router.push('/')
            this.carga = false
          }
        } else {
          this.$router.push('/')
        }
      },
      verificarUsuario () {
        axios
          .get(this.urlUsuario)
          .then((r) => {
            this.validarEstado(r)
          })
          .catch((e) => {
            console.log(e) // eslint-disable-line
            alert('NO SE PUEDE CONECTAR AL SERVICIO WEB')
            this.$router.push('/')
          })
      },
      formatDate (template, date) {
        const specs = 'YYYY:MM:DD:HH:mm:ss'.split(':')
        date = new Date(
          date || Date.now() - new Date().getTimezoneOffset() * 6e4,
        )
        return date
          .toISOString()
          .split(/[-:.TZ]/)
          .reduce(
            (template, item, i) =>
            // eslint-disable-line
              template.split(specs[i]).join(item),
            template,
          )
      },
    },
  }
</script>
